<template>
    <div>
        <van-cell-group title="">
            <van-field label="姓名" placeholder="请填写姓名" v-model="model.Visitor_Name"
                :readonly="model.Is_Sure == 0 ? false : true" />
            <van-field label="性别" readonly placeholder="请选择性别" v-model="model.Visitor_Sex" @click="showSex" />
            <van-popup v-model="sex.show" position="bottom">
                <van-picker :columns="sex.ls" @confirm="changeSex" show-toolbar @cancel="sex.show = false" />
            </van-popup>

            <van-field label="访问日期" placeholder="请选择访问日期" readonly v-model="model.Visite_Date" @click="changeDate" />
            <van-calendar v-model="date.show" @confirm="dateSel" :show-confirm="false" :allow-same-day="true"
                :min-date="new Date('2021/7/1')" />

            <van-field label="身份证号" placeholder="请输入身份证号" v-model="model.Visitor_Id_Numer"
                :readonly="model.Is_Sure == 0 ? false : true" />
            <van-field label="联系电话" placeholder="请输入联系电话" type="digit" v-model="model.Visitor_Mobile"
                :readonly="model.Is_Sure == 0 ? false : true" />
            <van-field label="工作单位" placeholder="请填写工作单位" v-model="model.Visitor_Work_Place"
                :readonly="model.Is_Sure == 0 ? false : true"></van-field>

            <van-field label="来访原因" placeholder="请填写来访原因" readonly v-model="rea.tea" @click="showReason"></van-field>
            <van-field v-if="rea.tea == '其他'" label="其他来访原因" placeholder="请填写来访原因" v-model="model.Reason"></van-field>

            <van-popup v-model="rea.show" position="bottom">
                <van-picker :columns="rea.ls" @confirm="changeReason" show-toolbar @cancel="rea.show = false" />
            </van-popup>

            <van-field label="被访问人" placeholder="请填写被访问人" v-model="model.Be_Visited_Name"
                :readonly="model.Is_Sure == 0 ? false : true"></van-field>

            <van-field v-if="model.Is_Sure" label="确认日期" placeholder="" v-model="model.Sure_Time" readonly></van-field>
            <van-field v-if="model.Is_Sure" label="确认人" placeholder="" v-model="model.Sure_Name" readonly></van-field>
        </van-cell-group>

        <div style="padding: 20px; text-align: center" v-if="!model.Is_Sure">
            <van-button type="info" size="normal" icon="success" style="width: 90%;" @click="itemOk">完 成</van-button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    data() {
        return {
            model: {},

            sex: {
                show: false,
                ls: ['男', '女']
            },
            date: { show: false },
            rea: { show: false, ls: ['会议', '访问', '其他'], tea: '' }
        }
    },
    mounted() {
        if (this.$route.query.id) {
            this.getList()
        }
    },
    methods: {
        getList() {
            let self = this;
            this.whale.remote.getResult({
                url: "/api/Mobile/VIS/VisRegister/Get?ID=" + self.$route.query.id,
                // data: { ID: self.$route.query.id },
                completed: function (its) {
                    console.log(its)
                    self.model = its.DATA;
                    if(its.DATA.Sure_Time)
                        self.model.Sure_Time = self.$moment(its.DATA.Sure_Time).format('YYYY-MM-DD hh:mm')
                    if (its.DATA.Reason != '会议' && its.DATA.Reason != '访问') {
                        self.rea.tea = '其他'
                    } else {
                        self.rea.tea = its.DATA.Reason
                    }
                }
            })
        },
        showSex() {
            if (this.model.Is_Sure) {
                this.sex.show = false
            } else {
                this.sex.show = true
            }
        },
        changeSex(e) {
            this.model.Visitor_Sex = e
            this.sex.show = false
        },
        changeDate() {
            if (this.model.Is_Sure) {
                this.date.show = false
            } else {
                this.date.show = true
            }
        },
        dateSel(e) {
            this.model.Visite_Date = moment(e).format('YYYY-MM-DD');
            this.date.show = false;
        },
        showReason() {
            if (this.model.Is_Sure) {
                this.rea.show = false
            } else {
                this.rea.show = true
            }
        },
        changeReason(e) {
            if (e == '其他') {
                this.rea.tea = e
                this.rea.show = false
                this.model.Reason = ''
            } else {
                this.model.Reason = e
                this.rea.tea = e
                this.rea.show = false
            }
        },
        itemOk() {
            let self = this;
            this.whale.remote.getResult({
                url: "/api/Mobile/VIS/VisRegister/SafeCheckUpdate",
                data: self.model,
                completed: function () {
                    self.$router.go(-1)
                }
            })
        }
    },
}
</script>
<style lang="less" scoped></style>